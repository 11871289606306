.aboutPravasi{
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('./../../assets/rangoli.png');
    background-position:left;
    background-blend-mode: multiply;
    @media (max-width: 768px) {
    height: 100%;
    background-position:top;
    }

   
    background-size: contain;
    background-repeat: no-repeat;
    
    .aboutPravasiContainer{
        display: flex;
        height: 100%;
        padding: 30px;
        @media (max-width: 768px) {
            flex-direction: column;
            padding: 8px;
        }
        
        .aboutPravasiLeft{
            width: 50%;
            height: 100%;
            padding: 10px;
            object-fit: contain;
        @media (max-width: 768px) {
        width: 100%;
        }
            .aboutPravasiLeftImage{
                width: 450px;
                height: 450px;
        @media (max-width: 768px) {
            width: 350px;
            height: 350px;
        }

            }

        }
        .aboutPravasiRight{
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        @media (max-width: 768px) {
        width: 100%;
        }
            h2{

                color: #F96503;
        @media (max-width: 768px) {
            text-align: center;
            margin: 0px;
        }
                
            }
            p{
                font-size: 18px;
                font-weight: 400;
                @media (max-width: 768px) {
                    font-size: 15px;
                    margin: 0px;
                }
            }
            
        }
    }
}

.whoWeAre{
    
    padding: 20px 40px;
    @media (max-width: 768px) {
        padding: 10px;
    }
    .whoWeAreContainer{
        display: flex;
        padding: 20px 40px;
        border-radius: 12px;
        background-color: #F96503;
        @media (max-width: 768px) {
            padding: 10px;
        }
        
        @media (max-width: 768px) {
            flex-direction: column-reverse;
        }

        .whoWeAreLeft{
            width: 60%;
            @media (max-width: 768px) {
                width: 100%;
            }
            h2{
                color: white;
                @media (max-width: 768px) {
                    text-align: center;
                    margin: 0px;
                }
            }
            p{
                color: white;
                @media (max-width: 768px) {
                    font-size: 15px;
                    margin: 0px;
                    text-align: justify;
                }
            }
        }
        .whoWeAreRight{
            width: 40%;
            @media (max-width: 768px) {
                width: 100%;
            }
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
}


.meetTheTeam{

    background-blend-mode: overlay; 
    background-image:  
    linear-gradient(rgba(255, 255, 255, 0.745), rgba(255, 255, 255, 0.849)), /* White overlay */
    url('./../../assets/opac.jpg');
    background-size: cover;                    /* Scale image to cover the box */
    background-position: center; 
    background-color: #ffffff88;
    padding: 20px;
    .meetTheTeamContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1{
            color: #F96503;
            @media (max-width: 768px) {
            margin: 0px;
            }
        }
        p{
            width: 70%;
            text-align: center;
            @media (max-width: 768px) {
                font-size: 12px;
                margin: 0px;
                width: 100%;

            }
        }

        .teamContainer{
            display: flex;
            align-items: center;
            justify-content: space-around;
            @media (max-width: 768px) {
            flex-direction: column;
            gap: 10px;
            }

            .teamCard{
                margin: 3px;
                border-radius: 10px;
                width: 25%;
                background-color: white;
                @media (max-width: 768px) {
                    width: 100%;
                }
                img{
                    width: 100%;
                    object-fit: cover;
                    border-radius: 4px;

                }
                .teamCardContent{
                    padding: 10px;
                h3{
                    color: #F96503;
                    margin: 2px;
                }
                
                h3{

                }
                p{
                    width: 100%;
                    text-align: justify;
                    font-size: 10px;
                    margin: 2px;
                    @media (max-width: 768px) {
                        font-size: 14px;
                        }
                }
            }
            }

        }
    }
}
.contactPageContainer{
    // background-color: blue;
    position: relative;
    .contactPage{
        display: flex;
        height: 95vh;
        // flex-direction: ;
        padding: 10px;
            padding: 100px;
            @media (max-width: 768px) {
                flex-direction: column;
                height: 100%;
            padding: 2px;
                    }
        .contactPageLeft{
            padding: 100px;
            width: 50%;
            background-image: url('../../assets/rangoli.png');
            background-position: left;
            background-size: cover;
            background-repeat: no-repeat;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @media (max-width: 768px) {
                padding: 5px;
                width: 100%;
                    }
            h2{
                color: #ED028E;
            }
            p{
                font-size: 15px;
            }
            h3{
                color: #F96503; 
            }
            .contactInfo{
                padding: 3px;
                display: flex;
                flex-direction: column;
                gap: 15px;
                list-style: none;
                .contactInfoList{
                    
                    span{
                        background-image: url('../../assets/bg-con.png');
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        padding: 6px;
                        margin-right: 7px;
                        
                    }
                }
                
            }
            .SocialLinks{
                display: flex;
                list-style: none;
                padding: 0;
                li{
                    background-color: #F96503;
                    padding: 15px;
                    border-radius: 50%;
                    margin: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        .contactPageRight{
            color: white;
            padding: 100px;
            background-image: url('../../assets/durga.png');
            background-repeat: no-repeat;
            background-position: top;
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            @media (max-width: 768px) {
                width: 100%;
                padding: 10px 2px;
                height: 600px;
                    }

            
            .contactPageRightContainer{
                width: 70%;

            @media (max-width: 768px) {
                width: 90%;
            }

                .contactForm{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    .form-group{
                        display: flex;
                        flex-direction: column;
                        
                        label{
                            font-size: 12px;
                            color: white;
                            margin-bottom: 5px;
                        }
                        .commonInput{
                            padding: 12px;
                            font-size: 14px;
                            border-radius: 5px;
                            border: none;

                            &:focus{
                                border: none;
                                outline: none;
                            }
                            &::selection{
                                border: none;
                            }
                        }
                    }
                }
            }
            
        }
    }
}
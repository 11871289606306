.SuccessReviewContainer{
    padding: 20px;
    background: url('../../../assets/rangoli2.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
    
    .SuccessReview{
        h2{
            color:#F96503;
            text-align: center;
            font-size: 28px;
        }
        p{
            color: #000;
            text-align: center;
        }
        .SuccessReviewSliderContainer{
            display: flex;
            align-items: center;
            justify-content: center;
            .SuccessReviewSlider{
                width: 80%;
                padding: 20px;
                .slick-slide > div{
                    margin: 20px;
                }

            

                .SuccessReviewCardContainer{
                    background-color: #FFFFFF;
                    height: 100%;
                    border-radius: 10px;
                    padding: 20px;
                   
                    text-align: left;
                    position: relative;
                    .SuccessReviewCardProfile{
                        position: absolute;
                        top: -18px;
                        left: -22px;
                        z-index: 9999;
                        border: 4px solid #F96503;
                        border-radius: 50%;
                        img{
                            width: 60px;
                            height: 60px;
                            border-radius: 50%;
                        }

                        @media (max-width: 768px) {
                            left: -18px;
                           }

                    }
                    .SuccessReviewCardTop{
                        padding-left: 30px;
                        padding-bottom: 10px;
                        h2{
                            margin: 0px;
                            text-align: left;
                            font-size: 18px;
                        }
                        span{
                            font-size: 14px;

                        }
                    }
                    p{
                        margin: 0px;
                        text-align: left;
                        font-size: 12px;
                    }
                    

                }
            }
        }
        .buttonView{
            display: flex;
            justify-content: center;
            align-items: center;
        }

    }

}
.headerContainer{
    height: 70px;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    
    .headerLeft{
        img{
            width: 120px;
        }
    }
    .menu-icon {
        display: none;
        font-size: 1.8rem;
        cursor: pointer;
        align-self: center;
    
        @media (max-width: 768px) {
          display: block;
          
        }
      }

    .headerRight{
        display: flex;
        align-items: center;
        justify-content: space-around;

        @media (max-width: 768px) {
            display: none;
            position: absolute;
            top: 60px;
            left: 0;
            width: 100%;
            background-color: #333;
            padding: 1rem 0;
            height: 100vh;
            z-index: 99;
          }
      
          &.open {
            display: block;
          }

        .headerMenu{
            padding: 10px;
            ul{
                list-style: none;
                display: flex;
                @media (max-width: 768px) {
                   flex-direction: column;
                   color: white;
                  }

                .menuList{
                    text-decoration: none;
                    font-size: 14px;
                    font-weight: 500; 
                    @media (max-width: 768px) {
                        height: 40px;
                        color: white;
                       }                                      
                }
                .menuList:hover{
                    color: white;
                }
                li{
                    padding: 15px 10px ;
                    font-size: 14px;
                    width: 60px;
                    height: 70px;
                    transition: ease-in 0.1s;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                    @media (max-width: 768px) {
                        width: 100px;
                        height: 100%;
                        padding: 0px 10px;
                        
                      }
                }
                li:hover{
                    background-image: url('./../../assets/frame.png');
                    background-repeat: no-repeat;
                    background-size:contain;
                    background-position: top;
                    cursor: pointer;
                    @media (max-width: 760px) {
                        background-image: none;
                      }


                    
                }
            }
        }
    }
}
*{
  margin: 0px;
  padding: 0px;

}
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;700&display=swap');

body {
  font-family: 'Jost', sans-serif;
}
.joinNowButton{
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 20px;
  background: linear-gradient(99.65deg, #5F22E2 3.95%, #BF22E2 21.24%, #FB9B2B 100%);
}

.whiteButton{
  padding: 10px 20px;
  color: black;
  background-color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0px 8px 12px 0px #00000040;

  @media (max-width: 768px) {
    padding: 7px 20px;
    font-weight: 600
   }
}
.WebsiteBody{
  width: 100vw;
}
.joinNowButton{
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 20px;
    background: linear-gradient(99.65deg, #5F22E2 3.95%, #BF22E2 21.24%, #FB9B2B 100%);
  }
  .whiteButton{
    padding: 10px 20px;
    color: black;
    background-color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: 0px 8px 12px 0px #00000040;
    @media (max-width: 768px) {
      padding: 7px 20px;
      font-weight: 600
     }
  }
  .orangeButton{
    padding: 10px 20px;
    color: white;
    background-color:#F96503;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: 0px 8px 12px 0px #00000040;
  }
  .overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); // Adjust opacity as needed
    z-index: 1000; // Ensures it appears above other elements
  } 
  .combineSection{
    // background: url('../../assets/vector1.png');
    position: relative;
    overflow: hidden;
    .combineSectionImage{
      width: 100%;
      position: absolute;
      top: -50%;
      right: 0px;
      left: 0px;
      opacity: 0.2;
      z-index: -1;
      overflow: hidden;


      @media (max-width: 768px) {
        overflow: hidden;
       }
    }
    .combineSectionImage2{
      top: -40%;
      right: 0px;
      left: 90%;
      width: 226px;
      opacity: 1;
      @media (max-width: 768px) {
        left: 45%;
       }
    }
  
  }
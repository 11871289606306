// .swiper {
//     width: 100%;
//   }
//   .swiper-slide {
//     height: 30%;
//     background: #882525;
//     line-height: 300px;
//     text-align: center;
//   }
//   .swiper-slide {
//     background: #8acc7d;
//     margin: 15px;
//   }
 
  .boxImage{
    height: 100%;
    width: 100%;
    background-color: blue;
   
  }
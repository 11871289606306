.HeroMainContainer {
    background: url('../../../assets/mahal.JPG') no-repeat center center;
    background-size: cover;
    height: 70vh; // Corrected '70Vh' to '70vh'
    position: relative;
    padding: 40px;
  
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.315); // Overlay with opacity
      z-index: 1;
    }
  
    .HeroMain {
      display: flex;
      justify-content: flex-end; // Changed from 'right' to 'flex-end' for better alignment
      align-items: center;
      height: 100%;
      position: relative;
      z-index: 2; // Ensure this stays above the overlay
      @media screen and (max-width: 768px) {
        justify-content: center;
      }
      .HeroMainContent {
        width: 250px;
        height: 73%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../../../assets/design12.png');
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
  
        h2 {
          font-size: 21px;
          text-transform: uppercase;
          text-align: center;
          background: linear-gradient(131.41deg, #BD0A43 19.05%, #FE6302 63.6%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
  
        .joinNowButton {
          margin-top: 20px;
          padding: 10px 20px;
          background-color: #FE6302;
          border: none;
          color: #fff;
          font-size: 16px;
          cursor: pointer;
          transition: background-color 0.3s;
  
          &:hover {
            background-color: #BD0A43;
          }
        }
      }
    }
  }
  
.info-section {
    padding: 20px;
    text-align: center;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("./../../../assets/rangoli.png");
   
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
   
   
    position: relative;

    
  }
  
  .info-section h2 {
    font-size: 2em;
    color: #f57c00;
  }
  
  .info-section p {
    max-width: 70%;
    color: black;
    text-align: center;
    font-size: 1.1em;
    @media screen and (max-width: 768px) {
      max-width: 85%;
    }

    
  }
  .flowerImage{
    width: 200px;
    position: absolute;
    right: 0;
    top: 30%;
  }
  .video-container {
    position: relative;
    display: inline-block;
  }
  
  .video-container img {
    width: 100%;
    max-width: 600px;
    border-radius: 8px;
  }
  
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2em;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
  }
  
  .read-more-btn {
    background-color: #f57c00;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    margin-top: -20px;
    z-index: 111;
    border-radius: 15px;
  }
  
.gallery-section {
    padding: 20px;
    // background-color: #f5f5f5;
    background-image: url('../../../assets/holy.JPG');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    mix-blend-mode: overlay;
   
    h2 {
      font-size: 2em;
      text-align: center;
      color: #f57c00;
    }
    
    
  }
  
   
  
  .gallerySection {
    display: flex;
    height: 354px;
    width: 70%;
    @media screen and (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
  }
  
  .mySwiper{
  img{
    width: 200px;
    height: 290px;
    border-radius: 15px;
  }
 }
  
  .view-all-btn {
    background-color: #f57c00;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 10px;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 15px;

  }
  .gallery-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5); /* White with 50% opacity */
    mix-blend-mode: overlay; /* Optional for different blending effects */
    pointer-events: none; /* Allows clicks to pass through */
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.faqContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('./../../assets/rangoli.png');
    background-position:left;
    background-blend-mode: multiply;   
    background-size: contain;
    background-repeat: no-repeat;
    
}

/* FAQ Section Container */
.faq-section {
    min-width: 800px;
    margin: 0 auto;
    padding: 20px;
    @media (max-width: 768px) {
      width: 95%;
      padding: 10px;
      min-width: 90%;
      .faq-list{
        width: 90%;
      }
    }


  }
  
  
  .faq-section h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  /* FAQ Item */
  .faq-item {
    border-bottom: 1px solid #25252533;
    padding: 20px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .faq-item:hover {
    background-color: #f9f9f9;
  }
  
  /* Question Styling */
  .faq-question {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  /* Answer Styling */
  .faq-answer {
    padding-top: 10px;
    font-size: 16px;
    color: #555;
    line-height: 1.5;
  }
  
  /* Arrow Icon */
  .arrow {
    font-size: 20px;
    color: #888;
  }
  
  /* Active FAQ Item */
  .active .faq-question {
    color: #007BFF; /* Change color for active item */
  }
  .needHelpButton{
    background-color:#F96503;
    border: 0;
    font-size: 18px;
    padding: 15px 20px;
    border-radius: 30px;
    color: white;
    margin-bottom: 20px;
  }

.galleryPage{
    padding: 10px;
    background-image: url("./../../assets/rangoli.png");
   
    background-size: contain;
    background-position: top-left;
    background-repeat: no-repeat;
    background-size: 600px;
    @media (max-width: 768px) {
        background-size: 400px;
    }
    
    .galleryTitle{
        padding: 10px 90px;
        @media (max-width: 768px) {
          padding: 10px;
        }
        
        .galleryTitleContainer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #F96503;

        @media (max-width: 768px) {
          flex-direction: column;
              }
        h2{

        }
        .search-bar{
            border: 1px solid black;
            border-radius: 20px;
            padding: 5px;
            input{
                padding: 5px;
                border: none;
                width: 300px;
                border-radius: 20px;
                outline: none;
            }
            .clear{
                cursor: pointer;
            }
        }

        }
    }

/* Gallery container */
.galleryImages{
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: none;

    @media (max-width: 768px) {
      padding: 2px;
          }
}
.gallery {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: start;
    @media (max-width: 768px) {
      width: 100%;
      justify-content: center;
          }
  }
  
  /* Individual gallery item */
  .gallery-item {
    position: relative;
    width: 32%;
    height: 350px;
    overflow: hidden;
    cursor: pointer;

    @media (max-width: 768px) {
      width: 47%;
          }
  }
  
  /* Image styling */
  .gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .gallery-item:hover .gallery-image {
    transform: scale(1.1);
  }
  
  /* Overlay for text */
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: end;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.3s ease;
  }
  
  .gallery-item:hover .overlay {
    opacity: 0;
  }
  
  /* Text styling */
  .text {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  
  /* Modal styles */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    background: #fff;
    padding: 20px;
    max-width: 90%;
    max-height: 90%;
    text-align: center;
    border-radius: 8px;
    overflow: auto;
  }
  
  .modal-image {
    width: 100%;
    max-width: 500px;
    border-radius: 8px;
  }
  
  .modal-text {
    margin-top: 10px;
    font-size: 18px;
    color: #333;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    color: #333;
  }
}
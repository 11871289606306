.subscribeFooterContainer{
    height: 200px;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
        margin-top: 90px;
    }

    .subscribeFooter{
    position: relative;
    width: 90%;
    z-index: 99;
    border-radius: 20px;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    background: linear-gradient(90deg, #ED028E 0%, #FD6800 100%);
    
    @media (max-width: 768px) {
        flex-direction: column;
        }
    
    .likeContainer{
        position: absolute;
        top: -60px;
        padding: 10px;
        left: 10px;
        background-color: #FC600A;
        border-radius: 50%;
        .likeImage{
            width: 25px;
            height: 25px;
        }
    }   
    
    .subscribeFooterLeft{
            color: white;
            font-size: 30px;
            font-weight: 600;

        }
        .subscribeFooterRight{
            .subscribeFooterRightForm{
                background-color: white;
                padding: 5px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                .subscribeInput{
                    border: none;
                    padding: 10px;
                    font-size: 16px;
                }
                .subscribeButton{
                    padding: 10px;
                    background: #ED028E;
                    border: none;
                    border-radius: 5px;
                    color: white;
                    font-size: 16px;
                    font-weight: 400;

                }

            }
        }

    }
}

.footer-container{
    display: flex;
    margin-top: -80px;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items:   center;
    background-color: #292929;
    padding:  50px 20px;
    color: white;

    @media (max-width: 768px) {
        flex-direction: column-reverse;


       }
    h6{
        color: #F96503;
        font-size: 20px;
        font-weight: 700;
        font-family: jost;
    }
    ul{
        list-style: none;
        padding: 0;
        font-size: 14px;
        font-weight: 400;
        color: #FFFAFA;
        @media (max-width: 768px) {
            text-align: center;
           }
    }


    .footer-box1{

    }
    .footer-box2{
        
    }
    .footer-box3{
        
    }
    .footer-box4{
        .qrcode-footer{
            
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .qrcodeScanner{
                width: 80px;
            }

            .appstore{
                display: flex;
                justify-content: center;
                align-items: center;
                a{
                    img{
                        width: 50px;
                    }
                }
            }
        }
    }
}
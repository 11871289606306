.registerContainer{
    width: 100vw;
    overflow: hidden;
    .registerContainerBox{
        display: flex;
        height: 99vh;
        position: relative;
        @media (max-width: 768px) {
            flex-direction:column-reverse;
            height: 100%;
        }
        .registerBoxLeft{
            width: 50%;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @media (max-width: 768px) {
                width: 94% !important;
            }  
            .registerBoxLeftContainer{
                width: 60%;
                @media (max-width: 768px) {
                    width: 70%;
                } 
                h2{
                    background: linear-gradient(131.41deg, #BD0A43 19.05%, #FE6302 63.6%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    text-align: center;
                }
                p{
                    text-align: center;
                    font-size: 14px;
                    a{
                        color: blue;
                        text-decoration: none;
                    }
                }
                .registerFormContainer{
                    .registerForm{
                        .form-group{
                            display: flex;
                            flex-direction: column;
                            label{
                                color: black;
                                margin: 6px 0px;
                                font-size: 12px;
                                font-weight: 500;
                            }
                            .commonInput{
                                padding: 8px;
                                background-color: #F2F2F2;
                                padding: 15px;
                                border: none;
                                border-radius: 10px;
                                display: flex;
                                align-items: center;
                                gap: 5px;
                            }
                            
                            

                        }
                        .checkbox-group{
                                display: flex;
                                flex-direction: column;
                                // align-items: center;
                                justify-content: center;
                                gap: 10px;
                                margin: 10px 0px;
                                label{
                                    color: black;
                                margin: 6px 0px;
                                font-size: 12px;
                                // font-weight: 500;
                                a{
                                    color: blue;
                                    text-decoration: none;
                                }
                                }
                        }
                        .signup-button{
                            padding: 15px;
                            text-align: center;
                            background: orangered;
                            border-radius: 5px;
                            border: none;
                            color: white;
                            text-decoration: none;
                            width: 100%;
                        }
                    }
                }

               
                
            }
        }
        .registerBoxRight{
            width: 50%;
            height: 100%;
            @media (max-width: 768px) {
                width: 100% !important;
                }
            .registerBoxRightImage{
              height: 100%;
              width: 100%;  
              object-fit: cover;
            }
        }
        .leftBottomContainer{
            
            padding: 10px;
            bottom: 0;
            width: 95%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #F96503;
            
            @media (max-width: 768px) {
                font-size: 12px;
                flex-direction: column;
                }
            .termsCondition{
            color: #F96503;
            text-decoration: none;
            @media (max-width: 768px) {
                font-size: 12px;
                }
                
            }
        }
    }
}







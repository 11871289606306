.sendRestPasswordContainer{
    .sendRestPasswordContainerBox{
        display: flex;
        height: 99vh;
        position: relative;
        .sendRestPasswordBoxLeft{
            width: 50%;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .sendRestPasswordBoxLeftContainer{
                h2{
                    background: linear-gradient(131.41deg, #BD0A43 19.05%, #FE6302 63.6%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    text-align: center;
                }
                p{
                    text-align: center;
                    font-size: 14px;
                    @media (max-width: 768px) {
                        margin: 25px;
                    }
                    a{
                        color: blue;
                        text-decoration: none;
                    }
                }
                .sendRestPasswordCodeContainer{
                    
                    .sendRestPasswordCodeForm{
                        .radio-group{
                            display: flex;
                            flex-direction: column;
                            gap: 15px;
                            .radioLabel{
                                display: flex;
                                .radioContent{
                                    display: flex;

                                    .radioContentDetail{
                                        p{
                                            margin: 0;
                                            text-align: left;
                                        }
                                    }
                                }
                            }
                        }
                        .form-group{
                            display: flex;
                            margin-top: 30px;
                            align-items: center;
                            
                            label{
                                color: black;
                                margin: 6px 0px;
                                font-size: 12px;
                                font-weight: 500;
                                width: 35%;
                                
                            }
                            .commonInput{
                                padding: 8px;
                                background-color: #F2F2F2;
                                padding: 15px;
                                border: none;
                                border-radius: 10px;
                                width: 100%;
                                display: flex;
                                align-items: center;
                                gap: 5px;
                            }
                            
                            
                            

                        }
                        .resetPasswordButtons{
                            display: flex;
                            justify-content: center;
                            gap: 10px;
                            margin-top: 20px;
                            .continue-button{
                                padding: 15px;
                                text-align: center;
                                background: orangered;
                                border-radius: 5px;
                                border: none;
                                color: white;
                                cursor: pointer;
                                text-decoration: none;
                                width: 40%;
                            }
                            .cancel-button{
                                width: 40%;

                                padding: 15px;
                                text-align: center;
                                background: white;
                                cursor: pointer;

                                border-radius: 5px;
                                border: none;
                                color: orangered;
                                border: 1px solid #E8E8EA;
                                
                                text-decoration: none;
                               
                            }
                        }
                        
                    }
                }

               
                
            }
        }
        .sendRestPasswordBoxRight{
            width: 50%;
            height: 100%;
            .sendRestPasswordBoxRightImage{
              height: 100%;
              width: 100%;  
              object-fit: cover;
            }
        }
        .leftBottomContainer{
            position: fixed;
            padding: 10px;
            bottom: 0;
            width: 49%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #F96503;

            @media (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                padding: 1px;
                
                p{
                    margin: 0;
                }
                a{
                    margin: 0;
                }
            }
            .termsCondition{
            color: #F96503;
            text-decoration: none;
                
            }
        }
    }
}







.ourPartnerContainer{
    height: 300px;

    .ourPartner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-content: center;
        padding: 10px;

        h1{
                text-align: center;
                color:#F96503 ;
        }
        .logoContainer{
            display: flex;
            // flex-direction: column;
            justify-content: center;
            gap: 10px;


            @media (max-width: 768px) {
                width: 100vw;
                overflow: scroll;
 
               }
            .logoBoxContainer{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            
            
                .logoBox{
                    padding: 10px;
                    width: 120px;
                    background-color: white;
                    border-radius: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        width: 80%;
                    }
                
            }
        }}
    }
}
.bannerHero{
    height: 300px;
    display: flex;
    flex-direction: row-reverse;
    padding: 0px 30px;
    background-color: bisque;
    align-items: end;
    background-image: url('./../../assets/backgroundImage.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: 768px) {
    justify-content: center;
    padding: 0px;


            }
    .bannerHeroBox{
        width: 20%;
        height: 60%;

        @media (max-width: 768px) {
        width: 80%;
            }
        // background-color: blue;
        background-image: url('./../../assets/bannerVector.PNG');
        background-position: center top;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        h1{
            background: -webkit-linear-gradient(#BD0A43, #FE6302);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        
    }
}